import React from "react";
import "animate.css";
import Firm from "./firms/firm";

function TrustedFirms() {
  return (
    <>
      <div className="sm:grid sm:grid-cols-12 gap-6 container m-auto mt-3 p-6 rows-3">
        <div className="col-span-12 text-white text-center mb-4">
          <div className="animate__animated animate__fadeInDown animate__delay-4s">
            <div className="sm:text-3xl text-2xl text-green-700 font-bold">
              Güvenilir Yardım Platformları
            </div>
            <p className="text-sm sm:text-base">
              Destekte bulunmak için kartların üzerine tıklayınız.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-12 gap-4 animate__animated animate__fadeIn animate__delay-5s">
          <div className="sm:col-span-6 col-span-12 gap-4 mb-4 sm:mb-0 border border-gray-500 hover:border-white hover:rounded-md duration-200 transition hover:ease-in hover:border-white hover:rounded-md duration-200 transition hover:ease-in">
            <a href="https://www.afad.gov.tr/filistin-yardim-kampanyasi">
              <Firm
                img="/firmLogos/AFAD.png"
                name="AFAD"
                desc="Günlerdir yoğun saldırı altında ölüm kalım mücadelesi veren Filistinli kardeşlerimiz için düzenlediğimiz yardım kampanyasına davet ediyor; bağışlarınızı Filistinli kardeşlerimize ulaştırıyoruz.

            Her zaman ve her koşulda mazlumun yanında olan aziz Milletimize teşekkürlerimizle…"
              />
            </a>
          </div>
          <div className="sm:col-span-6 col-span-12 gap-4 mb-4 sm:mb-0 border border-gray-500 hover:border-white hover:rounded-md duration-200 transition hover:ease-in">
            <a href="https://www.kizilay.org.tr/Bagis/BagisYap/32/filistin-genel-bagisi">
              <Firm
                img="/firmLogos/Kızılay.png"
                name="Türk Kızılayı"
                desc="Abluka altındaki Gazze’de 2 milyondan fazla insanın hayatı olumsuz etkilendi. Gazze halkı, suya, gıdaya, elektriğe, ilaca ve tıbbi yardıma acil ihtiyaç duyuyor.  Özellikle hastanelerin, okul ve yaşam alanlarının bombalanması sonrasında siviller zarar görüyor ve bölgede tıbbi malzeme, gıda, su ve hastaneler için enerji ihtiyacı gün geçtikçe hayati önem kazanıyor. "
              />
            </a>
          </div>
          <div className="sm:col-span-6 col-span-12 gap-4 mb-4 sm:mb-0 border border-gray-500 hover:border-white hover:rounded-md duration-200 transition hover:ease-in">
            <a href="https://ihh.org.tr/bagis/filistin-gazze">
              <Firm
                img="/firmLogos/IHH.png"
                name="İHH – İnsani Yardım Vakfı"
                desc="Filistinlilerin gıda, sağlık, barınma gibi birçok alanda yardıma ihtiyacı var. Şimdi Gazze ve Filistin için birlik olma vakti. Filistin halkı abluka ve ekonomik sıkıntıların yanı sıra birçok zorlukla mücadele ediyor. Birçok ülke ise İsrail’in saldırılarını görmezden geliyor.

            Yaşam alanları, mülteci kampları, hastaneler ve sivil halk hedef alınmaya devam ediyor."
              />
            </a>
          </div>
          <div className="sm:col-span-6 col-span-12 gap-4 mb-4 sm:mb-0 border border-gray-500 hover:border-white hover:rounded-md duration-200 transition hover:ease-in">
            <a href="https://www.care.org.tr/tr/bagis-yap/gazze-insani-yardim">
              <Firm
                img="/firmLogos/care.png"
                name="Çare Derneği"
                desc="İsrail’in son günlerdeki saldırıları sonucunda Gazze’ye yönelik bombardıman tekrar başlatıldı. İsrail, Gazze’ye zaten kısıtlı verdiği elektrik hizmetini tamamen kesmeye karar verdi. Bu, hastanelerin çalışmaması ve yüz binlerce insanın temel ihtiyaçlara ulaşamaması anlamına geliyor. Filistin halkının bu kritik dönemde sizin yardımlarınıza ihtiyacı var."
              />
            </a>
          </div>
          <div className="sm:col-start-4 sm:col-end-10 col-span-12 gap-4 border border-gray-500 hover:border-white hover:rounded-md duration-200 transition hover:ease-in">
            <a href="https://hayratyardim.org/bagis/filistin-acil-yardim">
              <Firm
                img="/firmLogos/hayratYardim.png"
                name="Hayrat Yardım"
                desc="Tüm bu saldırılar sonucunda hastane, cami ve okulların da bulunduğu birçok yerleşim yeri ağır hasar aldı. Gazze'ye düzenlenen bu saldırıların ardından yapılan son açıklamalara göre 4.710'u çocuk 11.500 Filistinli vefat ederken 17.000'den fazla kişinin yaralandığı belirtildi."
              />
            </a>
          </div>
        </div>
        <br />
      </div>
    </>
  );
}

export default TrustedFirms;
