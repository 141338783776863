import React from "react";
import "animate.css";

function MainText() {
  return (
    <>
      <div className="grid grid-cols-12 mt-5">
        <p className="text-xl sm:text-3xl font-bold text-center col-start-2 col-end-12 text-green-700 animate__animated animate__fadeInDown">
          Vicdan Sahibi Herkese
        </p>
        <div className="col-span-10 col-start-2 col-end-12 text-center text-sm sm:text-base text-white">
          <p className="animate__animated animate__fadeInDown animate__delay-1s mt-3">
            &emsp;&emsp;&emsp;1947 yılından beri tüm dünyanın gözü önünde katil
            ve faşist İsrail yönetimi Filistin halkına karşı sistematik soykırım
            uygulamaktadır. 7 Ekim 2023 tarihinden itibaren devam eden son
            soykırımda katil İsrail, hastaneler ve ibadethaneler dahil olmak
            üzere her türlü sivil yapıyı da hedef almaktadır. Kadın ve çocuk
            ayırt etmeden gerçekleştirilen bu soykırıma Birleşmiş Milletler
            dahil uluslararası kamuoyu göz yummaktadır. Siyonist İsrail'e
            ambargo ve yaptırım uygulanmadığı gibi çeşitli ülkelerden ve
            uluslararası şirketlerden maddi ve siyasi destekler ilk günden beri
            devam etmektedir. Sivil inisiyatiflerle başlatılan soykırım yanlısı
            şirketlere karşı boykot hareketleri katliamcılara karşı mücadelede
            önem arz etmektedir. Bu onurlu mücadelenin devamlılığı ve kalıcılığı
            vicdansız ve katil küresel düzene karşı bir pasif direniştir.
            Boykotun uzun süreli devam ederek tam anlamıyla başarılı olabilmesi
            için boykot ürünlerinin muadilleri piyasaya sürülmelidir. Katil
            düzenin tüm ürünlerine, sistemlerine ve hizmetlerine karşı boykot
            çağrımızı yeniliyor ve yeni bir parantez açıyoruz.
          </p>
          <p className="animate__animated animate__fadeInDown animate__delay-2s mt-6">
            &emsp;&emsp;&emsp;Küresel katil sistemin her türlü ürününe boykot
            uygulansa da en kolay ve karlı gelir kaynakları finansal piyasalar,
            uluslararası bankacılık sistemi ve ödeme yöntemleridir. Ülkemizde de
            yaygın olarak kullanılan kredi kartı ve banka kartlarının %95'i
            yabancı sermayelerin ürünleridir. Katil Siyonist İsrail'e destek
            açıklaması da yapan bu firmaların ülkemizden Ocak-Eylül 2023
            döneminde elde ettikleri gelir 5.2 milyar $'dır. İsrail güdümlü tüm
            firmaları ülkemizde boykot edilse dahi ödeme yöntemi olarak ürünleri
            kullanıldığı zaman boykot mücadelesi tam anlamıyla başarıya
            ulaşamayacaktır. Her gün İsrail'e bir kuşun hediye edip masum
            sivillerin, kadınların ve çocukların ölmesine yardım etmek
            istemiyorsanız kredi/banka kartlarınızı yerli ve milli ürünlere
            taşımaya ve cüzdanlarınızda kurşun taşımamaya davet ediyoruz.
          </p>
          <p className="animate__animated animate__fadeInDown animate__delay-3s mt-6">
            &emsp;&emsp;&emsp;Bu sitenin kurulma amacı ülkemiz üzerinden sadece
            iki uluslararası şirketin elde ettiği gelire dikkat çekmek ve bu
            milli servetin soykırım destekçileri yerine ülkemizde kalması
            yönünde kamuoyu oluşturmaktır. Terörist İsrail ve destekçileriyle
            olan mücadelemiz her alanda sürecektir. <br />
            <br />
            İsrail ürünlerini sorgulamak için:{" "}
            <a
              href="https://melahim.com"
              className="font-bold text-md text-red-700 cursor-pointer"
            >
              https://melahim.com
            </a>{" "}
            adresini ziyaret edebilirsiniz.
          </p>
        </div>
      </div>
    </>
  );
}

export default MainText;
