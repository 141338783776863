
import MainTitle from "../components/mainTitle";
import SideCard from "../components/sideCard";

function App() {
  return (
    <>
      <div className="body">
        <MainTitle />
      </div>
      <div className="sm:mt-10 mt-2">
        <SideCard />
      </div>
      <div className="m-auto container grid text-center text-white p-4 mt-5 mb-5">
        <p className="text-sm text-gray-600">
          GencAskon © 2023
          <br />
        </p>
      </div>
    </>
  );
}

export default App;
