import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components
import App from "./pages/App";
import Israel from "./pages/israel";
import Palestine from "./pages/palestine";
import Header from "./components/header";

// Styles
import "./style/index.css";

const RoutesComponent = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/israel" element={<Israel />} />
        <Route path="/palestine" element={<Palestine />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <RoutesComponent />
  </React.StrictMode>,
  document.getElementById("root")
);
