import React from "react";

function Firm(props) {
  return (
    <>
      <div className="col-span-6">
        <div className="grid grid-cols-12 p-7">
          <div className="col-span-4 m-auto">
            <img src={process.env.PUBLIC_URL + props.img} alt="" width="75%" />
          </div>
          <div className="col-span-8 text-white">
            <h3 className="font-bold text-center text-xl">{props.name}</h3>
            <p className="mt-2 text-sm">{props.desc}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Firm;
