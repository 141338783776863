import React from "react";
import "animate.css";

function MainTitle() {
  return (
    <div className="sm:grid sm:grid-cols-12 gap-4 content-center mt-5">
      <div className="sm:text-3xl text-xl text-center font-bold col-start-2 col-end-12 text-white animate__animated animate__fadeIn">
        Milli Servetine Sahip Çık!
      </div>
      <div className="p-3 sm:p-0 sm:text-base text-sm text-center col-span-10 text-white justify-center col-start-2 animate__animated animate__fadeIn animate__delay-1s">
        Bu sitenin kurulma amacı ülkemiz üzerinden sadece iki uluslararasi
        şirketin sadece 2023 yılında elde ettiği <b>5.2 Milyar $</b> gelire
        dikkat çekmek ve bu milli servetin soykırım destekçileri yerine
        ülkemizde kalması yönünde kamuoyu oluşturmaktır. Terörist İsrail ve
        destekçileriyle olan mücadelemiz her alanda sürecektir.
      </div>
    </div>
  );
}

export default MainTitle;
