import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainText from "../components/mainText";
import TrustedFirms from "../components/trustedFirms";
import "animate.css";

function Palestine() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MainText />
      <TrustedFirms />
      <div className="m-auto container grid text-center text-white p-4 sm:mt-5 mt-0 mb-5">
        <p className="text-sm text-gray-600">
          GencAskon © 2023
          <br />
        </p>
      </div>
    </>
  );
}

export default Palestine;
