import React from "react";

function Product(props) {
  return (
    <div className="sm:col-span-3 col-span-6 border border-gray-600 row-2 text-white">
      <div className="p-3">
        <img
          src={process.env.PUBLIC_URL + props.img}
          alt="Product"
          className="shadow-lg"
        />
        <h2 className="text-center font-bold mt-2 text-lg mb-1">
          {props.name}
        </h2>
        <p className="sm:text-md text-sm text-center">{props.desc}</p>
        <h1 className="text-center font-bold sm:text-xl text-base mt-1">
          {props.priceString} $
        </h1>
      </div>
      <div className="grid grid-cols-12 b-2 border-t border-b border-gray-600 text-2xl">
        <button
          className="col-span-6 border-r border-gray-600 text-gray-600 hover:text-white transition duration-100 ease-in-out"
          onClick={() => props.removeToBasket(props.name, props.price)}
        >
          -
        </button>
        <button
          className="col-span-6 text-gray-600 hover:text-white transition duration-100 ease-in-out"
          onClick={() => {
            props.addToBasket(props.name, props.price);
            props.playMusic();
          }}
        >
          +
        </button>
      </div>
      <div className="grid grid-cols-12 text-center mt-1 p-1">
        {props.secenekler.map((secenek) => (
          <div className="col-span-3 cursor-pointer" key={secenek}>
            <button
              onClick={() => {
                props.selectMultiplier(secenek);
              }}
              style={{
                fontWeight:
                  props.selectedSecenek === secenek ? "bold" : "normal",
                color: props.selectedSecenek === secenek ? "white" : "gray",
              }}
            >
              {secenek}x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;
