import React from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";

function Card() {
  const navigate = useNavigate();
  const rotatePalestine = () => navigate("/palestine");
  const rotateIsrael = () => navigate("/israel");

  return (
    <div className="grid text-white sm:gap-10 gap-3 grid-cols-12">
      <div
        className="sm:col-start-3 sm:col-end-7 col-start-3 col-end-11 m-auto bg-black rounded border border-gray-600 animate__animated animate__fadeIn animate__delay-2s cursor-pointer"
        onClick={rotatePalestine}
      >
        <img
          className="grayscale hover:grayscale-0 transition duration-300 ease-in-out"
          src={process.env.PUBLIC_URL + "/filistin.jpg"}
          width="100%"
          alt="Palestine"
          onClick={rotatePalestine}
        />
      </div>
      <div
        className="sm:col-start-7 sm:col-end-11 col-start-3 col-end-11 m-auto bg-black rounded border border-gray-600 animate__animated animate__fadeIn animate__delay-2s cursor-pointer"
        onClick={rotateIsrael}
      >
        <img
          className="grayscale hover:grayscale-0 transition duration-300 ease-in-out"
          width="100%"
          src={process.env.PUBLIC_URL + "/israil.jpg"}
          alt="Palestine"
        />
      </div>
    </div>
  );
}

export default Card;
