import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Header() {
  const navigate = useNavigate();
  const rotatePalestine = () => navigate("/palestine");
  const rotateIsrael = () => navigate("/israel");
  const rotateMain = () => navigate("/");

  return (
    <>
      <Helmet>
        <title>Ne Kazandırdın?</title>
        <meta
          name="description"
          content="Bu sitenin kurulma amacı ülkemiz üzerinden sadece iki uluslararasi şirketin sadece 2023 yılında elde ettiği 5.2 milyar $ gelire dikkat çekmek ve bu milli servetin soykırım destekçileri yerine ülkemizde kalması yönünde kamuoyu oluşturmaktır. Terörist İsrail ve destekçileriyle olan mücadelemiz her alanda sürecektir."
          encoding="UTF-8"
        />
      </Helmet>

      <div className="grid grid-cols-12 text-white text-center">
        <div
          className="sm:col-span-5 col-span-4 sm:p-4 p-2 font-light border sm:text-base text-sm border-white border-opacity-20 hover:bg-black/70 hover:font-bold hover:text-white transition duration-300 ease-in-out cursor-pointer"
          onClick={rotatePalestine}
        >
          Vicdan Sahibi Herkese
        </div>
        <div
          className="sm:col-span-2 col-span-4 sm:p-4 p-2 font-light border sm:text-base text-sm border-white border-opacity-20 hover:bg-black/70 hover:font-bold hover:text-white transition duration-300 ease-in-out cursor-pointer"
          onClick={rotateMain}
        >
          Anasayfa
        </div>
        <div
          className="sm:col-span-5 col-span-4 sm:p-4 p-2 font-ligh border sm:text-base text-sm border-white border-opacity-20 hover:bg-black/70 hover:font-bold hover:text-white transition duration-3npm 00 ease-in-out cursor-pointer"
          onClick={rotateIsrael}
        >
          İsrail'e Ne Kazandırdın?
        </div>
      </div>
    </>
  );
}

export default Header;
