import React, { useState, useEffect } from "react";
import Product from "../components/product";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import "animate.css";

function Israel() {
  // Scroll to top when page changes
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Audio player for bomb sound
  const [audio] = useState(new Audio(process.env.PUBLIC_URL + "/bomb.mp3"));

  const playMusic = () => {
    audio.play();
  };

  const stopMusic = () => {
    audio.pause();
  };

  // Format number with money format
  function paraFormati(sayi) {
    return sayi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  const [budget, setBudget] = useState(5200000000);
  const [grayScale, setGrayScale] = useState(100);

  // Background image
  const BackgroundContainerWeb = styled.div`
    background-image: url(${process.env.PUBLIC_URL + "/background.jpg"});
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  `;

  const BackgroundContainerMobile = styled.div`
    background-image: url(${process.env.PUBLIC_URL + "/background.jpg"});
    background-size: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  `;

  // Product names
  const productNames = [
    "F-16",
    "IRON DOME",
    "ATMOS 2000",
    "MERKAVA",
    "IDF M270 MLRS",
    "Fosfor Bombası",
    "MK 84",
    "Tavor Tar 21",
  ];

  // Initial products
  const initialProducts = productNames.reduce(
    (obj, productName) => ({ ...obj, [productName]: 0 }),
    {}
  );

  const [selectedProducts, setSelectedProducts] = useState(initialProducts);
  const [total, setTotal] = useState(5200000000);

  // Calculate grayscale
  useEffect(() => {
    setGrayScale((100 * total) / budget);
  }, [total, budget]);

  // Define multiplier
  const [secenekler] = useState([1, 5, 10, 50]);

  // Select multiplier
  const [seciliSecenek, setSeciliSecenek] = useState(1);

  // Select multiplier
  const handleSecenekSecimi = (secenek) => {
    setSeciliSecenek(secenek);
  };

  // Select box
  const [selectBoxValue, setSelectBoxValue] = useState("all");

  // Change total for months
  const handleChangeTotal = (event) => {
    const selectedValue = event.target.value;

    // Update total and budget for months
    setSelectBoxValue((prevSelectBoxValue) => {
      switch (selectedValue) {
        case "all":
          setTotal(5200000000);
          setBudget(5200000000);
          setSelectedProducts(initialProducts);
          break;
        case "ocak":
          setTotal(463521602);
          setBudget(463521602);
          setSelectedProducts(initialProducts);
          break;
        case "subat":
          setTotal(423716764);
          setBudget(423716764);
          setSelectedProducts(initialProducts);
          break;
        case "mart":
          setTotal(538346795);
          setBudget(538346795);
          setSelectedProducts(initialProducts);
          break;
        case "nisan":
          setTotal(561379702);
          setBudget(561379702);
          setSelectedProducts(initialProducts);
          break;
        case "mayis":
          setTotal(594085275);
          setBudget(594085275);
          setSelectedProducts(initialProducts);
          break;
        case "haziran":
          setTotal(515447870);
          setBudget(515447870);
          setSelectedProducts(initialProducts);
          break;
        case "temmuz":
          setTotal(507653730);
          setBudget(507653730);
          setSelectedProducts(initialProducts);
          break;
        case "agustos":
          setTotal(539734153);
          setBudget(539734153);
          setSelectedProducts(initialProducts);
          break;
        case "eylul":
          setTotal(531998537);
          setBudget(531998537);
          setSelectedProducts(initialProducts);
          break;
        case "ekim":
          setTotal(525016805);
          setBudget(525016805);
          setSelectedProducts(initialProducts);
          break;
        default:
      }
      return selectedValue;
    });
  };

  // Add to basket
  const addToBasket = (name, value) => {
    if (total - value * seciliSecenek < 0) {
      alert(
        "Daha fazla destek olmak için lütfen otoriter ödeme desenlerine ait kart kullanım ücreti ödeyiniz. Bu ödemeleriniz İsrail'in savunma bütçesine aktarılacaktır. Teşekkürler."
      );
      return;
    } else {
      setSelectedProducts((prevProducts) => ({
        ...prevProducts,
        [name]: (prevProducts[name] || 0) + 1 * seciliSecenek,
      }));
      setTotal(total - value * seciliSecenek);
    }
  };

  const removeToBasket = (name, value) => {
    if (total + value * seciliSecenek > 5200000000) {
      stopMusic();
      return;
    } else if (selectedProducts[name] === 0) {
      alert("Ürün sayısı 0'dan küçük olamaz.");
      return;
    } else {
      setSelectedProducts((prevProducts) => ({
        ...prevProducts,
        [name]: (prevProducts[name] || 0) - 1 * seciliSecenek,
      }));
      setTotal(total + value * seciliSecenek);
    }
  };

  return (
    <>
      <BackgroundContainerWeb
        style={{ filter: `grayscale(${grayScale}%)` }}
        className="sm:visible"
      ></BackgroundContainerWeb>

      <BackgroundContainerMobile
        style={{ filter: `grayscale(${grayScale}%)` }}
        className="visible sm:invisible"
      ></BackgroundContainerMobile>

      <div className="grid grid-cols-12 mt-5">
        <div className="sm:text-3xl text-xl text-center font-bold col-start-2 col-end-12 text-white animate__animated animate__fadeInDown">
          Kalan Bütçe: {paraFormati(total)} $
        </div>
        <div className="sm:text-md sm:my-0 my-3 text-md text-center col-start-2 col-end-12 text-white animate__animated animate__fadeInDown">
          <p>
            Yukarıda belirtilen bütçe, 2023 yılında sadece iki uluslararası kart
            şirketin elde ettiği gelirdir. Milli servetimizden elde edilen bu
            gelir ile terörist İsrail'e aşağıdaki askeri envanterlerden ne kadar
            destek verdiğinin farkında mısın?
          </p>
        </div>
        <div className="col-span-12 text-white sm:text-base text-sm text-center mt-2 text-white animate__animated animate__fadeInDown animate__delay-1s">
          <p className="mb-2 text-sm">Listenen Ay</p>
          <select
            value={selectBoxValue}
            className="bg-gray-500/0 border border-gray-600 p-1 mr-2"
            onChange={handleChangeTotal}
          >
            <option className="text-black" value="all">
              Tümü
            </option>
            <option className="text-black" value="ocak">
              Ocak
            </option>
            <option className="text-black" value="subat">
              Şubat
            </option>
            <option className="text-black" value="mart">
              Mart
            </option>
            <option className="text-black" value="nisan">
              Nisan
            </option>
            <option className="text-black" value="mayis">
              Mayıs
            </option>
            <option className="text-black" value="haziran">
              Haziran
            </option>
            <option className="text-black" value="temmuz">
              Temmuz
            </option>
            <option className="text-black" value="agustos">
              Ağustos
            </option>
            <option className="text-black" value="eylul">
              Eylül
            </option>
            <option className="text-black" value="ekim">
              Ekim
            </option>
          </select>

          <select className="bg-gray-500/0 border border-gray-600 p-1">
            <option className="text-black" value="">
              2023
            </option>
          </select>
        </div>

        {Object.keys(selectedProducts).length > 0 && (
          <div className="grid grid-cols-12 col-start-2 col-end-12 mt-4 text-white gap-3 text-white animate__animated animate__fadeIn animate__delay-2s">
            {Object.entries(selectedProducts).map(([name, quantity], index) => (
              <p className="sm:col-span-3 col-span-6 border text-center border-gray-600 pt-3 pb-3 text-sm">
                {quantity}x
                <br />
                <span className="font-bold text-base">{name}</span>
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 mt-5">
        <div className="grid grid-cols-12 col-start-2 col-end-12 gap-3 text-white text-white animate__animated animate__fadeIn animate__delay-3s">
          <Product
            img="/f16.jpg"
            name="F-16"
            desc="Çok amaçlı bir bombardıman uçağıdır. Hava ve kara hedeflerine sevk edebilir."
            priceString="63.000.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={63000000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/irondome.jpg"
            name="IRON DOME"
            desc="İsrail'in, hava saldırılarına karşı oluşturduğu gelişmiş bir hava savunma sistemidir."
            priceString="55.000.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={55000000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/howitzer.jpg"
            name="ATMOS 2000"
            desc="ATMOS 2000, 155 mm çapındaki namlusuyla uzun menzilli ateş gücü sağlar."
            priceString="6.750.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={6750000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/merkava.jpg"
            name="MERKAVA"
            desc="İsrail tarafından İsrail Savunma Kuvvetleri için üretilen ana muharebe
          tankıdır."
            priceString="5.000.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={5000000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/mlrs.jpg"
            name="IDF M270 MLRS"
            desc="Birden fazla roketi barındıran güdümsüz veya güdümlü roket atabilen bir sistemdir."
            priceString="4.300.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={4300000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/fosfor.jpg"
            name="Fosfor Bombası"
            desc="Fosfor dumanını teneffüs eden kişinin havasızlıktan boğulmasına yol açmaktadır."
            priceString="20.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={20000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/mk.jpg"
            name="MK 84"
            desc="MK84, yaklaşık 2.000 pound ağırlığında, TNT içeren yüksek patlayıcı bombadır."
            priceString="16.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={16000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
          <Product
            img="/tar21.jpg"
            name="Tavor Tar 21"
            desc="Olumsuz savaş koşullarında kaliteyi en üst düzeye çıkarmak için tasarlanan tüfektir."
            priceString="2.000"
            addToBasket={addToBasket}
            removeToBasket={removeToBasket}
            price={2000}
            selectMultiplier={handleSecenekSecimi}
            selectedSecenek={seciliSecenek}
            secenekler={secenekler}
            playMusic={playMusic}
          />
        </div>
      </div>
      <div className="m-auto container grid text-center text-white p-4 mt-5 mb-5">
        <p className="text-sm text-gray-600">
          *Listelenen ürünlerin fiyatları yaklaşık değerlerdir.
          <br /> GencAskon © 2023
          <br />
        </p>
      </div>
    </>
  );
}

export default Israel;
